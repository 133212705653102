/* global window */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { shape, string, arrayOf } from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Contact from "../components/Contact"
import Intro from "../components/Intro"
import Logo from "../components/Logo"
import Work from "../components/Work"
import Resume from "../components/Resume"
import SEO from "../components/seo"

const AnimationOn = styled.div`
  .logo {
    opacity: 0;
    animation: 0.6s ease-out 1.1s 1 fadeIn forwards;
  }
  .intro {
    p {
      animation: 0.4s ease-out 1s 1 slideIn forwards;
      opacity: 0;
    }
    p:first-child {
      animation: 0.4s ease-out 0.1s 1 fadeIn forwards;
    }
  }
  .work {
    opacity: 0;
    transform: translateY(20vh);
    animation: 0.3s ease-out 1s 1 slideUp forwards;
  }
`

const Hidden = styled.div`
  opacity: 0;
`

const AnimationOff = styled.div``

const IndexPage = ({ data }) => {
  const [component, setComponent] = useState(Hidden)

  useEffect(() => {
    if (!window.animated) {
      setComponent(AnimationOn)
      window.animated = true
    } else {
      setComponent(AnimationOff)
    }
  }, [])

  const Wrapper = component

  return (
    <Layout>
      <SEO
        title="Jens Fischer | Tech Lead and Full Stack Developer from NYC"
        description="I take pride in clean, scalable work, that's built with attention to detail."
      />
      <Wrapper>
        <Logo />
        <Intro html={data.intro.html} />
        <Work data={data.work.nodes} />
        <Resume html={data.resume.html} />
        <Contact />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    intro: markdownRemark(frontmatter: { type: { eq: "intro" } }) {
      html
      frontmatter {
        type
        order
      }
    }
    work: allMarkdownRemark(filter: { frontmatter: { type: { eq: "work" } } }) {
      nodes {
        html
        frontmatter {
          title
          excerpt
          role
          technology
          type
          thumb
          order
          path
          color
        }
      }
    }
    resume: markdownRemark(frontmatter: { type: { eq: "resume" } }) {
      html
      frontmatter {
        type
        order
      }
    }
  }
`

IndexPage.propTypes = {
  data: shape({
    intro: shape({
      html: string,
    }),
    work: shape({
      nodes: arrayOf(
        shape({
          html: string,
        })
      ),
    }),
    resume: shape({
      html: string,
    }),
  }).isRequired,
}

export default IndexPage
