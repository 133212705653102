import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Text from "./Text"
import { container } from "../theme/config"

const IntroSection = styled.div`
  ${container}
  justify-content: center;
  padding-bottom: 150px;
  padding-bottom: 10vh;

  p {
    white-space: pre-line;
    font-weight: 300;
  }

  p:first-child {
    font-weight: 400;
  }
`

const Intro = ({ html }) => (
  <Text
    typeStyle="heading"
    color="medium"
    as={IntroSection}
    className="intro"
    dangerouslySetInnerHTML={{ __html: html }}
  />
)

Intro.propTypes = {
  html: PropTypes.string.isRequired,
}

export default Intro
