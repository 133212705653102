import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Text from "./Text"
import { font, container, fontMain } from "../theme/config"

const ResumeWrapper = styled.div`
  ${container}
  padding-top: 100px;
`

const List = styled.div`
  box-sizing: border-box;
  position: relative;

  p {
    padding-left: 0%;
    padding-top: 10px;
    letter-spacing: 1px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-left: 20%;
    }
  }

  h4 {
    padding-left: 0%;
    padding-top: 40px;
    font-weight: 700;

    span {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.bright};
      display: block;
      position: relative;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-left: 20%;
      span {
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }

  a {
    display: inline-block;
    text-align: left;
    margin: 0 auto;
    padding-top: 40px;
    color: #528cb4;
    text-decoration: none;
    position: relative;

    &:before {
      position: absolute;
      display: inline-table;
      height: 1px;
      width: 100%;
      content: "";
      background: #528cb4;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) scale(0);
      transition: transform 0.2s ease-out;
    }

    &:hover {
      text-decoration: none;
      &:before {
        transform: translateX(-50%) scale(1);
      }
    }
  }

  p:first-child {
    padding: 0px 0 45px 0;
    font-size: 24px;
    line-height: 40px;
  }

  p:last-child {
    text-align: center;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      text-align: left;
    }
  }
`

const Resume = ({ html }) => (
  <ResumeWrapper>
    <Text color="dark" as="h2" typeStyle="subhead">
      Experience
    </Text>
    <Text
      typeStyle="body"
      as={List}
      color="medium"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </ResumeWrapper>
)

Resume.propTypes = {
  html: PropTypes.string.isRequired,
}

export default Resume
