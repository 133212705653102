import React from "react"
import { arrayOf, shape, string, number } from "prop-types"
import styled from "styled-components"
import { layout, space } from "styled-system"
import { Link } from "gatsby"
import Text from "./Text"
import { container, breakpoints } from "../theme/config"

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    z-index: 2;
    content: "";
    display: block;
    position: absolute;
    background: #f8f8f8;
    top: 200px;
    left: -100%;
    width: 300%;
    height: calc(100% - 100px);
    transform: rotate(5deg);
  }
`
const WorkSection = styled.div`
  ${container}
  position: relative;
  z-index: 3;

  h2 {
    margin-bottom: 50px;
  }
`

const WorkItem = styled.div`
  display: flex;
  margin-bottom: 70px;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints[1]}) {
    flex-direction: row;
  }
`

const Detail = styled.div`
  ${layout}
  ${space}
  box-sizing: border-box;

  h3 {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;
    padding-bottom: 20px;

    span {
      color: ${({ theme }) => theme.colors.medium};
      display: inline;
      font-weight: 400;
    }
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  p,
  li {
    padding-bottom: 20px;
    padding-bottom: 20px;
  }
  span {
    font-weight: 700;
    display: block;
  }
  a {
    color: #528cb4;
    text-decoration: none;
    position: relative;
    &:before {
      position: absolute;
      display: block;
      height: 1px;
      width: 100%;
      content: "";
      background: #528cb4;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%) scale(0);
      transition: transform 0.2s ease-out;
    }
    &:hover {
      text-decoration: none;
      &:before {
        transform: translateX(-50%) scale(1);
      }
    }
  }
`

const Thumb = styled(Link)`
  ${layout}
  ${space}
  position: relative;
  font-size: 0;
  display: block;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.dark};
  padding-top: 64%;
  cursor: pointer;
  transition: background 0.3s ease-out;

  @media screen and (min-width: ${breakpoints[1]}) {
    padding-top: 32%;
  }

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.3s ease-out;
  }

  &:hover {
    background: ${({ color }) => color};
    img {
      transform: translate(-50%, -50%) scale(1.075);
    }
  }
`

const Work = ({ data }) => {
  const sortedData = data.sort((a, b) =>
    a.frontmatter.order < b.frontmatter.order ? -1 : 1
  )
  return (
    <Wrapper className="work">
      <WorkSection>
        <Text color="dark" as="h2" typeStyle="subhead">
          Selected Work
        </Text>
        {sortedData.map(work => (
          <WorkItem key={work.id}>
            <Thumb
              to={work.frontmatter.path}
              width={[1, 1, 0.5]}
              color={work.frontmatter.color}
            >
              <img src={work.frontmatter.thumb} alt={work.frontmatter.title} />
            </Thumb>
            <Detail width={[1, 1, 0.5]} pl={[0, 0, 40]} pt={[20, 20, 0]}>
              <Text typeStyle="body" as="h3">
                Role:&nbsp;
                <span>{work.frontmatter.role}</span>
              </Text>
              <Text color="medium" typeStyle="body">
                {work.frontmatter.excerpt}
              </Text>
              <Text typeStyle="body" as={Link} to={work.frontmatter.path}>
                View Details
              </Text>
            </Detail>
          </WorkItem>
        ))}
      </WorkSection>
    </Wrapper>
  )
}

Work.propTypes = {
  data: arrayOf(
    shape({
      id: string,
      frontmatter: shape({
        order: number,
        role: string,
        path: string,
        excerpt: string,
        thumb: string,
        title: string,
      }),
    })
  ).isRequired,
}

export default Work
